<template>
  <QDialog
    v-model="authStore.showInviteCodeModal"
    persistent
  >
    <div class="!max-w-screen w-full md:w-xl pt-6 px-6 !shadow-none">
      <div class="relative rounded dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          icon="close"
          flat
          round
          dense
          text-color="white"
          size="18px"
          class="absolute -top-6 -right-6 bg-gradient-primary"
        />
        <QForm
          class="w-full p-5 flex flex-col gap-5"
          @submit.prevent="submit"
        >
          <div class="flex flex-nowrap items-center gap-2 w-full border-b border-secondary text-2xl font-bold py-5">
            <Logo
              class="w-35"
              :dark="$q.dark.isActive"
            />
          </div>
          <div class="flex flex-col justify-center items-center gap-2 py-5">
            <QInput
              v-model="form.code"
              placeholder="추천인 코드를 입력하세요"
              outlined
              dense
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div>
            <QBtn
              dense
              unelevated
              rounded
              type="submit"
              :loading="loading"
              class="w-full h-15 bg-gradient-primary"
            >
              <div class="text-base font-bold">
                확인
              </div>
            </QBtn>
          </div>
        </QForm>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import {api} from '@/plugins/axios.js'
import {useAuthStore} from '@/stores/auth.js'
import {reactive, watch} from 'vue'
import {useRequest} from 'vue-request'
import {whenever} from '@vueuse/core'
import { useSettingStore } from '@/stores/setting'

const authStore = useAuthStore()
const settingStore = useSettingStore()
const form = reactive({
  code: null,
})

watch(settingStore, () => {
  form.code = settingStore.inviteCode
}, { immediate: true })

const { runAsync, loading } = useRequest(() => api.post('invite-codes/check', { code: form.code }))

async function submit () {
  await runAsync()
  localStorage.setItem('code', form.code)
  authStore.showInviteCodeModal = false
  authStore.showRegistrationModal = true
  // await router.push({ name: 'registration', params: { code: form.code } })
}

whenever(() => authStore.showInviteCodeModal, () => {
  localStorage.setItem('code', '')
})
</script>
